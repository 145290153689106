import { Routes } from '@angular/router';


export const ROOT_DASHBOARD_ROUTES: Routes = [
    {
        path: 'dashboard',
        data: { expectedRole: 'root' },
        loadChildren: () =>
            import('../../modules/dashboard/dashboard.module').then(
                (m) => m.DashboardModule
            )
    },
    {
        path: 'users',
        data: { expectedRole: 'root' },
        loadChildren: () =>
            import('../../modules/users/users.module').then(
                (m) => m.UsersModule
            )
    },
    {
        path: 'plans',
        data: { expectedRole: 'root' },
        loadChildren: () =>
            import('../../modules/plans/plans.module').then(
                (m) => m.PlansModule
            )
    },
    {
        path: 'roles',
        data: { expectedRole: 'root' },
        loadChildren: () =>
            import('../../modules/roles/roles.module').then(
                (m) => m.RolesModule
            )
    },
    {
        path: 'preferences',
        data: { expectedRole: 'root' },
        loadChildren: () =>
            import('../../modules/preferences/preferences.module').then(
                (m) => m.PreferencesModule
            )
    },
    {
        path: 'domains',
        data: { expectedRole: 'root' },
        loadChildren: () =>
            import('../../modules/domians/domians.module').then(
                (m) => m.DomiansModule
            )
    },
    {
        path: 'settings',
        data: { expectedRole: 'root' },
        loadChildren: () =>
            import('../../modules/settings/settings.module').then(
                (m) => m.SettingsModule
            )
    }
]
