import { Routes } from '@angular/router';
import { ContentLayoutComponent } from '@layouts/content-layout/content-layout.component';
import { DashboardLayoutComponent } from '@layouts/dashboard-layout/dashboard-layout.component';
import { AUTH_ROUTES } from '@routes/content-layout.routes';
import { ROOT_DASHBOARD_ROUTES } from '@routes/dashboard-layout.routes';
import { AuthGuardService } from '@services/auth-guard.service';
import { RoleGuardService } from '@services/role-guard.service';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'auth',
    pathMatch: 'full',
  },
  {
    path: 'auth',
    canActivate: [AuthGuardService],
    canActivateChild: [AuthGuardService],
    component: ContentLayoutComponent,
    data: { title: 'Auth Views' },
    children: AUTH_ROUTES
  },
  {
    path: 'panel',
    canActivate: [RoleGuardService],
    canActivateChild: [RoleGuardService],
    component: DashboardLayoutComponent,
    data: { title: 'Dashboard', expectedRole: 'root' },
    runGuardsAndResolvers: 'always',
    children: ROOT_DASHBOARD_ROUTES,
  },
];
