import { Routes } from '@angular/router';

//Route for content layout without sidebar, navbar and footer for pages like Login, Registration etc...

export const AUTH_ROUTES: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('../../modules/auth/auth.module').then((m) => m.AuthModule),
  },
];
