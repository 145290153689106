import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from './authentication.service';
import { LocalStorageService } from './local-storage.service';
import { USER_TYPES } from '@utils/constants';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  constructor(
    public router: Router,
    public authService: AuthenticationService,
    public localStorageService: LocalStorageService,
  ) { }

  canActivate(): boolean {
    if (this.authService.isAuthenticated()) {
      const userType: any = this.authService.getUserType();
      switch (userType) {
        case USER_TYPES.ROOT:
          this.router.navigate([`/panel`]);
          break;
        default:
          this.authService.doLogout()
          break;
      }
      return false;
    }
    return true;
  }

  canActivateChild(): boolean {
    if (this.authService.isAuthenticated()) {
      const userType: any = this.authService.getUserType();
      switch (userType) {
        case USER_TYPES.ROOT:
          this.router.navigate([`/panel`]);
          break;
        default:
          this.authService.doLogout()
          break;
      }
      return false;
    }
    return true;
  }
}
